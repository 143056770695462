
import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Link from '../../components/Link.js';

const Terms = () => {

    const { t } = useTranslation();

    return (

        <main className='valign-center'>

            <div className='h2 pt-3 text-center'>{t('terms.title')}</div>
            <div className='p-1 pb-2 text-left auto-overflow'>{parse(t('terms.content'))}</div>
            <Link className='back' to='back'>{t('common.back')}</Link>

        </main>        
    )
}

export default Terms;
