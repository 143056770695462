
import { useAppState } from '../../hooks//useAppState.js';
import image_dark from '../../assets/images/dark/blur.svg';
import image_light from '../../assets/images/light/blur.svg';

const BtnBlur = ({ onClick, ...props }) => {

    const [ state ] = useAppState();
    const image = state.theme === 'dark' ? image_dark : image_light;

    const onBtnClick = (e) => {
        onClick(e, true);
    }

    const onBtnUnclick = (e) => {
        onClick(e, false);
    }

    return <img {...props} src={image} alt='blur' onMouseDown={onBtnClick} onTouchStart={onBtnClick} onMouseUp={onBtnUnclick} onTouchEnd={onBtnUnclick} />
}
export default BtnBlur;
