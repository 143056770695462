
import { useAppState } from '../../hooks//useAppState.js';
import image_dark from '../../assets/images/dark/msg-sent.svg';
import image_light from '../../assets/images/light/msg-sent.svg';

const MsgSentIcon = ({ ...props }) => {

    const [ state ] = useAppState();
    const image = state.theme === 'dark' ? image_dark : image_light;

    return <img {...props} src={image} alt='message sent' />
}
export default MsgSentIcon;
