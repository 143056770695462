
import { createContext, useContext } from 'react';
import { useAppState } from './useAppState.js';
import config from '../../package.json';

const reconnectionDelay = config.reconnectionDelay ?? '1000';
const RECONNECT_TIME = +reconnectionDelay + (1 + Math.random()) + 1000;

const context = createContext();

export const AppConnection = ({ children}) => {

    const [state, dispatch] = useAppState();

    /**
     * Connect to the remote server through websocket
     */
    const connect = async (serverUrl) => {

        await disconnect();
        console.log(`connecting to ${serverUrl}`);

        // Attach listeners
        const websocket = new WebSocket(serverUrl);
        websocket.onopen    = ()  => handleMessage({ type: 'connected' });
        websocket.onclose   = (e) => handleMessage({ type: 'disconnected', e });
        websocket.onmessage = (e) => handleMessage(JSON.parse(e.data));
        websocket.onerror   = (e) => handleMessage({ type: 'error', error: e });

        // Keep websocket in state
        dispatch({ type: 'serverUrl' , payload: serverUrl });
        dispatch({ type: 'websocket', payload: websocket  });

        return websocket;
    }

    /**
     * Close the connection to the remote server (from client request)
     */
    const disconnect = async () => {

        const websocket = state.websocket;

        if (websocket) {

            // Remove all listeners
            websocket.onopen    = null;
            websocket.onclose   = null;
            websocket.onmessage = null;
            websocket.onerror   = null;
            websocket.close();

            // Clear ref to websocket
            dispatch({ type: 'websocket', payload: null  });
        }
    }

    /**
     * Send a message to the remote server, through the websocket
     */
    const sendMessage = async (msg) => {

        if (state.websocket) {
            state.websocket.send(JSON.stringify(msg));
            console.log(`message sent: ${JSON.stringify(msg)}`);
        }
    }
        
    /**
     * A message has been received from the websocket
     */
    const handleMessage = (msg) => {

        console.log(`message received: ${JSON.stringify(msg)}`);
        dispatch({ type: 'received', payload: msg  });

        // Try to automatically recover a lost connection
        // disconnect reason: https://www.rfc-editor.org/rfc/rfc6455#section-7.4.1
        if (msg.type === 'disconnected' && msg.e.code !== 1000) {
            const serverUrl = msg.e.currentTarget.url;
            setTimeout(async () => { connect(serverUrl) }, RECONNECT_TIME);
        }
    }

    return (
        <context.Provider value={{ connect, disconnect, sendMessage }}>
            {children}
        </context.Provider>
    )
}

export const useConnection = () => {
    return useContext(context);
}
