
const EmailUtil = () => {

    /**
     * Validation email with the following rules:
     * 
     *      only string with format: x@x.x
     *      last x can only be 2 or 3 char length
     *      before @: allow only chars [a-b.-]
     *      after  @: allow only chars [a-b.-]
     */
    const isValid = (email) => {
        const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(email)
    }

    return {
        isValid
    }

}

export default EmailUtil();