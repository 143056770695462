
import React from 'react';
import './AnimatedDots.css';

const AnimatedDots = ({ className, ...props }) => {

    return (
           
        <div {...props} className='animated-dots'>
            <span className={className} />
            <span className={className} />
            <span className={className} />
        </div>
    )
}

export default AnimatedDots;
