
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useController } from '../src/hooks/useController.js';
// import React, { useEffect } from 'react';
// import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
// import { Routes, Route, useNavigate } from 'react-router-dom';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import { useLastLocation } from "react-router-dom-last-location";

// import Home     from './pages/home/Home.js';
import Home     from './pages/home/Home.js';
import Code     from './pages/code/Code.js';
import CodeNew  from './pages/code/CodeNew.js';
import CodeSent from './pages/code/CodeSent.js';
import Privacy  from './pages/legal/Privacy.js';
import Terms    from './pages/legal/Terms.js';
import Howto    from './pages/legal/Howto.js';
import Error    from './pages/error/Error.js';
import Chat     from './pages/chat/Chat.js';
import Settings from './pages/settings/Settings.js';
import Logoff   from './pages/logoff/Logoff.js';

import './App.css';

const App = () => {

    /*
    const navigate = useNavigate();
    const location = useLocation();
    const lastLocation = useLastLocation();
    */
    window.controller = useController();

    // First time, initialize the application controller
    // eslint-disable-next-line
    useEffect(() => window.controller.init(), []);

    return (
        // <TransitionGroup className={ getTransitionClassName(location, lastLocation)} >
            // <CSSTransition key={location.key} timeout={800} classNames='page'>
                // <Routes location={location}>
                <Routes>
                    <Route path='/'             element={<Home />} index />
                    <Route path='/home'         element={<Home />} />
                    <Route path='/code/new'     element={<CodeNew />} />
                    <Route path='/code/sent'    element={<CodeSent />} />
                    <Route path='/code/:id'     element={<Code />} />
                    <Route path='/code'         element={<Code />} />
                    <Route path='/privacy'      element={<Privacy />} />
                    <Route path='/terms'        element={<Terms />} />
                    <Route path='/howto'        element={<Howto />} />
                    <Route path='/error'        element={<Error />} />
                    <Route path='/chat'         element={<Chat />} />
                    <Route path='/logoff'       element={<Logoff />} />
                    <Route path='/settings'     element={<Settings />} />
                </Routes>
            // </CSSTransition>

        // </TransitionGroup>
    )
}

/*
const transitions = [ 
    'home-code', 
    'code-privacy', 
    'code-terms', 
    'code-howto', 
    'code-chat', 
    'chat-settings', 
    'chat-logoff' 
]
*/

/**
 * Retrieve the navigation path from current/previous location
 */
/*
const getTransitionClassName = (location, lastLocation) => {

    const currentPath = location ? location.pathname.replace('/', '') : '';
    const lastPath    = lastLocation ? lastLocation.pathname.replace('/', '') : '';
    const navpath     = (lastPath ? lastPath : 'home') + '-' + (currentPath ? currentPath : 'home');

    // First page is not animated
    if (navpath === 'home-home') {
        return 'nothing';
    }

    // Detect pages with forward animations
    if (transitions.includes(navpath) || navpath.endsWith('error')) {
        return 'rightToLeft';
    }

    // Otherwise, just return an backward animation
    return 'leftToRight';
}
*/
    
export default App;
