
import React, { useState } from 'react';

import './Toggle.css';

const ToggleButton = ({ value, onChange }) => {

    const [ selected, setSelected ] = useState(value);

    const onToggleClick = () => {
        setSelected(!selected);
        onChange && onChange(!selected);
    }

    return (
        <div className="btn-toggle" onClick={onToggleClick}>
            <div className={`toggle ${selected ? 'enabled' : ''}`} />
        </div>
    )
}

export default ToggleButton;
