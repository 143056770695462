
const TimeUtil = () => {

    /**
     * Convert a time in secs into a formatted string (HH:mm:ss)
     */
    const toString = (time) => {

        var seconds  = parseInt(time % 60);
        var minutes  = parseInt(time / 60 % 60);
        var hours    = parseInt(time / 3600 % 24);

        hours   = hours   < 10 ? '0' + hours   : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return hours + ':' + minutes + ':' + seconds;
    }

    return {
        toString,
    }
}

export default TimeUtil();


    