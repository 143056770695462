
import { createContext, useContext, useReducer } from "react";

const initialState = {

    // application internal state
    websocket: null,
    serverUrl: null,
    authenticated: false,
    uid: null,
    token: null,
    received: null, // message received
    showErrorPage: true,
    error: null,
    
    // settings state
    theme: 'dark',
    lang: 'en',
    autoblur: false,
    autoexit: false,
    autoconfirm: false,

    // chat state
    userConnected: false,
    partnerConnected: false,
    connectionTime: 0,
    timerStatus: 'stopped',
    blurred: false,
    userTyping: false,
    partnerTyping: false,

    messages: []
    
    // messages: [
    //     { key: 1, content: 'message sent', status: 'sent'},
    //     { key: 2, content: 'message received', status: 'received'},
    //     { key: 3, content: 'message confirmed', status: 'confirmed'},
    //     { key: 4, content: 'message sent', status: 'sent'},
    //     { key: 5, content: 'message received, but not confirmed', status: 'received'},
    // ],
}

const context = createContext();

export const AppState = ({ children }) => {

    const reducer = (state, action) => {

        // console.log(`action: ${JSON.stringify(action)}`);
        
        switch(action.type) {
            
            case 'reset':
                return { ...initialState, ...action.payload };
            case 'websocket':
                return { ...state, websocket: action.payload };
            case 'serverUrl':
                return { ...state, serverUrl: action.payload };
            case 'authenticated':
                return { ...state, authenticated: action.payload };
            case 'uid':
                return { ...state, uid: action.payload };
            case 'token':
                return { ...state, token: action.payload };
            case 'received':
                return { ...state, received: action.payload };
            case 'showErrorPage':
                return { ...state, showErrorPage: action.payload };
            case 'error':
                return { ...state, error: action.payload };
        
            case 'theme':
                return { ...state, theme: action.payload };
            case 'lang':
                return { ...state, lang: action.payload };
            case 'autoblur':
                return { ...state, autoblur: action.payload };
            case 'autoexit':
                return { ...state, autoexit: action.payload };

            case 'userConnected':
                return { ...state, userConnected: action.payload };
            case 'partnerConnected':
                return { ...state, partnerConnected: action.payload };
            case 'connectionTime':
                return { ...state, connectionTime: action.payload };
            case 'timerStatus':
                return { ...state, timerStatus: action.payload };
            case 'blurred':
                return { ...state, blurred: action.payload };
            case 'userTyping':
                return { ...state, userTyping: action.payload };
            case 'partnerTyping':
                return { ...state, partnerTyping: action.payload };
            case 'messages':
                return { ...state, messages: action.payload };

            default:
                return state;
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <context.Provider value={{ state, dispatch }}>
            {children}
        </context.Provider>
    )
}

export const useAppState = () => {
    const { state, dispatch } = useContext(context);
    return [ state, dispatch ];
}

