
import { useAppState } from '../hooks//useAppState.js';
import image_dark_enabled from '../assets/images/dark/start-enabled.svg';
import image_dark_disabled from '../assets/images/dark/start-disabled.svg';
import image_light_enabled from '../assets/images/light/start-enabled.svg';
import image_light_disabled from '../assets/images/light/start-disabled.svg';

const ArrowDown = ({ enabled, ...props }) => {

    const [ state ] = useAppState();

    const arrow = state.theme === 'dark' 
        ? enabled ? image_dark_enabled : image_dark_disabled
        : enabled ? image_light_enabled : image_light_disabled;

    return <img {...props} src={arrow} alt='arrow down' />
}

export default ArrowDown;
