
import base64 from 'base-64';
import { v4 as uuidv4 } from 'uuid';
import EmailUtil from '../utils/EmailUtil.js';

const AppLogic = () => {
    
    /**
     * Validate that all emails passed in argument are valid email addresses
     */
    const areEmailsValid = (emails) => {

        let emailsValid = true;
        const uniqueEmails = [ ...new Set([ ...emails ]) ];

        for(let email of uniqueEmails) {
            if (!EmailUtil.isValid(email)) {
                emailsValid = false;
                break;
            }
        }

        return emailsValid;
    }

    /**
     * Check if user are connected
     */
    const isUserConnected = (uid, members) => {
        return members.includes(uid);
    }

    /**
     * Check if user are connected
     */
    const isPartnerConnected = (uid, members) => {
        const membersWithoutUser = members.filter(id => id !== uid);
        return membersWithoutUser.length > 0;
    }

    /**
     * Decode the token passed in argument
     */
    const decodePayload = (token) => {

        // Get token encoded content
        const start   = token.indexOf('.');
        const end     = token.lastIndexOf('.');
        const payload = token.substring(start+1, end);

        // Decode the token payload
        const decoded = base64.decode(payload);

        // And convert it to a json object
        return JSON.parse(decoded);
    }

    const createAuthMessage = (token) => {
        return {
            token,
            type: 'auth',
        }
    }

    const createTextMessage = (token, text) => {
        return { 
            token,
            type: 'text', 
            key: uuidv4(), 
            content: text
        }
    }

    const createTextAckMessage = (token, msg) => {
        return { 
            token,
            type: 'ack', 
            key: msg.key,
        }
    }

    const createTypingMessage = (token, isTyping) => {
        return {
            token,
            type: 'typing', 
            state: isTyping, 
            key: uuidv4(), 
        }
    }

    return {
        areEmailsValid,
        isUserConnected,
        isPartnerConnected,
        decodePayload,
        createAuthMessage,
        createTextMessage,
        createTextAckMessage,
        createTypingMessage
    }
}

export default AppLogic();
