import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../hooks/useAppState.js';
import { useController } from '../../hooks/useController.js';
import Link from '../../components/Link.js';
import Toggle from '../../components/Toggle.js';
import DropDown from '../../components/DropDown.js';

import './Settings.css';
import config from '../../../package.json';

// Config environment
const VERSION = config.version;

const Settings = () => {

    const { t } = useTranslation();
    const [ state ] = useAppState();
    const controller = useController();

    return (

        <main className='valign-center'>

            <div className='h2 pt-3 text-center'>{t('settings.title')}</div>

            <div className='fill settings-content'>
                <table>
                    <tbody>

                        <tr>
                            <td><label>{t('settings.version')}</label></td>
                            <td><span>{VERSION}</span></td>
                        </tr>

                        <tr>
                            <td><label>{t('settings.language')}</label></td>
                            <td><DropDown value={state.lang} options={t('settings.languages')} onChange={(value) => controller.setLanguage(value)} /></td>
                        </tr>

                        <tr>
                            <td><label>{t('settings.darkmode')}</label></td>
                            <td><Toggle value={state.theme === 'dark'} onChange={(value) => controller.setTheme(value ? 'dark' : 'light')} /></td>
                        </tr>

                        <tr>
                            <td><label>{t('settings.autoblur')}</label></td>
                            <td><Toggle value={state.autoblur} onChange={(value) => controller.setAutoBlur(value)} /></td>
                        </tr>

                        <tr>
                            <td><label>{t('settings.confirm')}</label></td>
                            <td><Toggle value={state.autoconfirm} onChange={(value) => controller.setAutoConfirm(value)} /></td>
                        </tr>

                        <tr>
                            <td><label>{t('settings.autoexit')}</label></td>
                            <td><Toggle value={state.autoexit} onChange={(value) => controller.setAutoExit(value)} /></td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <Link className='back' to='back'>{t('common.back')}</Link>

        </main>
    )
}

export default Settings;
