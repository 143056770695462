import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useController } from '../../hooks/useController.js';
import Link from '../../components/Link.js';
import Logo from '../../components/Logo.js';
import ArrowDown from '../../components/ArrowDown.js';

import './Code.css';

const CodeSent = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const controller = useController();

    const parseAndReplace = (label) => {
        
        const options = {
            replace: (node) => {

                switch(node.attribs?.id) {
                    case 'privacy': return <Link className='link' to='/privacy'>{domToReact(node.children, options)}</Link>;
                    case 'terms':   return <Link className='link' to='/terms'>{domToReact(node.children, options)}</Link>; 
                    case 'howto':   return <Link className='link' to='/howto'>{domToReact(node.children, options)}</Link>;   
                    default:        return null;
                }
            }
        }

        return parse(t(label), options);
    }

    /**
     * Event handler
     */

    const onStartChat = async () => {
        controller.navigate('/code');
    }

    return (

        <main className='valign-center'>

            <Logo className='pt-25' />

            <p className='h4 pt-4 text-center'>{t('codesent.title')}</p>
            <p className='h5 p-4 text-center'>{parse(t('codesent.message'))}</p>

            <ArrowDown className='btn-start' enabled={true} />

            <div className='fill'>
                <div className='h4 p-1 btn-start-enabled' onClick={onStartChat}>
                    {t('code.start')}
                </div>
            </div>

            <div className='p-1 pb-2 text-center'>{parseAndReplace('code.legal')}</div>

        </main>
    )
}

export default CodeSent;
