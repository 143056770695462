import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useController } from '../../hooks/useController.js';
import Logo from '../../components/Logo.js';
import ArrowDown from '../../components/ArrowDown.js';

import './Code.css';

const Code = () => {

    /**
     * Page state
     */
    
    const { t } = useTranslation();
    const controller = useController();
    const { id } = useParams();

    const [code, setCode] = useState(id ?? '');
    const [enabled, setEnabled] = useState( code?.trim().length > 3 );

    const parseAndReplace = (label) => {
        
        const options = {
            replace: (node) => {

                switch(node.attribs?.id) {
                    case 'new':     return <Link className='link' to='/code/new'>{domToReact(node.children, options)}</Link>;
                    case 'privacy': return <Link className='link' to='/privacy'>{domToReact(node.children, options)}</Link>;
                    case 'terms':   return <Link className='link' to='/terms'>{domToReact(node.children, options)}</Link>;
                    case 'howto':   return <Link className='link' to='/howto'>{domToReact(node.children, options)}</Link>;
                    default:        return null;
                }
            }
        }

        return parse(t(label), options);
    }

    /**
     * Event handler
     */

    const onStartChat = () => {
        controller.startChat(code);
    }
    
    const onCodeChange = (e) => {
        setCode(e.target.value);
        setEnabled(e.target.value?.trim().length > 3);
    }

    return (

        <main className='valign-center'>

            <Logo className='pt-25' />

            <div className='h5 pt-4 text-center'>{t('code.code')}</div>

            <input 
                type='text' 
                value={code} 
                className='h5 p-2 text-center border' 
                placeholder={t('code.code-placeholder')} 
                onChange={onCodeChange} 
            />

            <div className='h6 pt-1 text-center'>{parseAndReplace('code.new')}</div>

            <ArrowDown className='btn-start' enabled={enabled} />

            <div className='fill'>
                <div className={'h4 p-1 ' + ( enabled ? 'btn-start-enabled' : 'btn-start-disabled')} onClick={onStartChat}>
                    {t('code.start')}
                </div>
            </div>

            <div className='p-1 pb-2 text-center'>{parseAndReplace('code.legal')}</div>

        </main>
    )
}

export default Code;
