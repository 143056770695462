
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from '../../hooks/useController.js';

import './Logoff.css';

const Logoff = () => {

    const { t } = useTranslation();
    const controller = useController();

    const onQuitClick = () => {
        controller.stopChat(true);
    }

    const onCancelClick = () => {
        controller.navigate('back');
    }

    return (

        <main className='valign-center'>

            <div className='h2 pt-3 text-center'>{t('logoff.title')}</div>
            
            <p className='p-4'>{t('logoff.message')}</p>

            <div className='btn-group'>
                <div className='btn-confirm' onClick={onQuitClick}>{t('logoff.confirm')}</div>
                <div className='btn-cancel'  onClick={onCancelClick}>{t('logoff.cancel')}</div>
            </div>


        </main>
    )
}

export default Logoff;
