
import { useAppState } from '../hooks//useAppState.js';
import image_dark_enabled from '../assets/images/dark/send-enabled.svg';
import image_dark_disabled from '../assets/images/dark/send-disabled.svg';
import image_light_enabled from '../assets/images/light/send-enabled.svg';
import image_light_disabled from '../assets/images/light/send-disabled.svg';

const ArrowUp = ({ enabled, className, ...props }) => {

    const [ state ] = useAppState();

    const arrow = state.theme === 'dark' 
        ? enabled ? image_dark_enabled : image_dark_disabled
        : enabled ? image_light_enabled : image_light_disabled;

    return <img {...props} className={className + (enabled ? ' enabled' : ' disabled')} src={arrow} alt='arrow up' />
}

export default ArrowUp;
