
import React, { useState } from 'react';

import './Dropdown.css';

const DropDown = ({ options, value, onChange }) => {

    const [ selected, setSelected ] = useState(value);

    const onSelectClick = (value) => {
        setSelected(value);
        onChange && onChange(value);
    }

    return (

        <select value={selected} onChange={(e) => onSelectClick(e.target.value)}>
            { options.map((item) => <option key={item.value} value={item.value}>{item.label}</option>) }
        </select>                    
    )
}

export default DropDown;
