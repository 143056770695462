
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppState } from './useAppState.js';
import { useConnection } from './useConnection.js';
// import { useTimer } from './useTimer.js';
import AppLogic from '../logic/AppLogic.js';

import config from '../../package.json';
import header_dark from '../assets/images/dark/header.svg';
import header_light from '../assets/images/light/header.svg';

// Config environment
const BLUR_DELAY = config.blurDelay ?? '1000';
const SERVER_URL = (config.serverUrl ?? 'http://localhost:7000') + '/api';

export const useController = () => {

    const { t, i18n } = useTranslation();
    const routerNavigate = useNavigate();
    const [state, dispatch] = useAppState();
    const connection = useConnection();
    // const timer = useTimer();

    // Set background css variable according to current theme
    document.documentElement.style.setProperty('--header-background', `url(${state.theme === 'dark' ? header_dark : header_light})`);

    // Detect message received by the connection
    let handleMessage;
    useEffect(() => { state.received && handleMessage(state.received) }, [state.received, handleMessage]);

    /**
     * Initialize the controller.
     */
    const init = () => {
        setTheme('dark');
        //setTimeout(() => navigate('code'), +STARTUP_TIME);
    }

    /**
     * Change current displayed page and navigator to pathname
     */
    const navigate = (pathname) => {
        pathname === 'back' ? routerNavigate(-1) : routerNavigate(pathname);
    }

    /**
     * Change the current display language
     */
    const setLanguage = (lang) => {
        dispatch({ type: 'lang', payload: lang  });
        i18n.changeLanguage(lang);
    }

    /**
     * Change current display theme (dark or light)
     */
    const setTheme = (theme) => {

        if (theme === 'dark') {
            document.documentElement.style.setProperty('--text-color', '#ffffff');
            document.documentElement.style.setProperty('--text-bold-color', '#75ffed');
            document.documentElement.style.setProperty('--text-disabled-color', '#484e5c');
            document.documentElement.style.setProperty('--background-color', '#1b1e28');
            // document.documentElement.style.setProperty('--header-background', `url(${HeaderDark})`);
            document.documentElement.style.setProperty('--header-text-color', '#ffffff');
            document.documentElement.style.setProperty('--header-text-family', 'FontRegular');
        }
        else {
            document.documentElement.style.setProperty('--text-color', '#000000');
            document.documentElement.style.setProperty('--text-bold-color', '#d45434');
            document.documentElement.style.setProperty('--text-disabled-color', '#484e5c');
            document.documentElement.style.setProperty('--background-color', '#ffffff');
            // document.documentElement.style.setProperty('--header-background', `url(${HeaderLight})`);
            document.documentElement.style.setProperty('--header-text-color', '#ffffff');
            document.documentElement.style.setProperty('--header-text-family', 'FontBold');
        }

        dispatch({ type: 'theme', payload: theme  });
    }

    /**
     * Change current blurmode (true = autoblur, false manual blur)
     */
    const setAutoBlur = (autoblur) => {
        dispatch({ type: 'autoblur', payload: autoblur  });
        dispatch({ type: 'blurred', payload: autoblur });
    }

    /**
     * Change current auto close mode 
     * (true = close without warning, false close with confirm dialog)
     */
    const setAutoExit = (autoexit) => {
        dispatch({ type: 'autoexit', payload: autoexit  });
    }

    /**
     * Change current auto close mode 
     * (true = close without warning, false close with confirm dialog)
     */
    const setAutoConfirm = (autoconfirm) => {
        dispatch({ type: 'autoconfirm', payload: autoconfirm  });
    }

    /**
     * Change message blur state
     * true = messages blurred, false = messages not blurred
     */
    const setBlurredMessages = (value) => {
        const blurredMessages = state.autoblur ? !value : !state.blurred;
        dispatch({ type: 'blurred', payload: blurredMessages  });

    }

    /**
     * Create two chat codes and send an email for each one
     */
    const createCodes = async (userEmail, partnerEmail) => {
        await axios.post(`${SERVER_URL}/codes/new`, { userEmail, partnerEmail });
    }

    /**
     * Start a new chat session with the code passed in argument
     * Note: 
     *      with async arrow function, this is always null (don't know why).
     *      so in order to passe the controller to the method connection.connect, 
     *      the controller must be provided by the caller of startChat
     */
    const startChat = async (code) => {

        try {

            // Retrieve the token associated to the code
            const result = await axios.get(`${SERVER_URL}/codes/${code}`);

            if (!result.data.ok) {
                showError(result.data.error);
            }
            else {

                // Get the token and its decoded payload
                const token = result.data.payload;
                const payload = AppLogic.decodePayload(token);

                // Update app state
                dispatch({ type: 'reset' });
                dispatch({ type: 'token' , payload: token });
                dispatch({ type: 'uid' , payload: payload.uid });

                // Connect to the server
                const websocket = await connection.connect(payload.srv);

                // Check for 5 sec timeout
                setTimeout(() => {
            
                    // If the websocket is still unconnected, display an error
                    if (websocket.readyState !== 1) {
                        connection.disconnect();
                        showError({ code: 'ERROR_CONNECTION_TIMEOUT' });
                    }

                }, 3000);

            }
        }
        catch (e) {
            showError(e);
        }
    }

    /**
     * Stop the current chat session
     */
    const stopChat = async (force = false) => {

        // Go to logoff screen
        if (!state.autoexit && !force) {
            navigate('logoff');
        }
        else {

            // Disconnect from the server and stop the timer
            // timer.stop();

            // Disconnect to the server
            connection.disconnect();

            // Reset app state
            dispatch({ type: 'reset' });

            // Return to code page
            navigate('/code');
        }
    }

    /**
     * Send a chat message to the remote server
     */
    const sendMessage = (text) => {

        // Clear partner typing message
        sendTyping(false);

        // Send message to the remote partner
        const msg = AppLogic.createTextMessage(state.token, text);
        connection.sendMessage(msg);

        // Change app state
        const newMsg = { key: msg.key, content: msg.content, status: 'sent'}
        dispatch({ type: 'messages', payload: [...state.messages, newMsg] });

        // Unblur messages area, if it is currently blurred
        if (state.blurred) {
            dispatch({ type: 'blurred', payload: false });
            setTimeout(() => dispatch({ type: 'blurred', payload: true }), +BLUR_DELAY);
        }

    }

    /**
     * Send a typing message to the remote server
     */
    const sendTyping = (typing) => {

        // Send message to the remote partner
        const msg = AppLogic.createTypingMessage(state.token, typing);
        connection.sendMessage(msg);

        // Change app state
        dispatch({ type: 'userTyping', payload: typing  });
    }

    /**
     * Try to translate the exception passed in argument
     * and navigate to the error page
     */
    const showError = (e) => {

        if (state.showErrorPage) {

            let code = null;
            let message = null;

            // Adjust error message
            if (e.code === 'ERR_NETWORK') {
                message = t('error.connection');
                code = null;
            }
            else if (e.code === 'INVALID_CODE') {
                message = t('error.code');
                code = null;
            }
            else if (e.code === 'ERROR_CONNECTION_TIMEOUT') {
                message = t('error.timeout');
                code = null;
            }
            else if (e.type === 'error') {
                message = t('error.server');
                code = null;
            }
            else {
                message = e.message;
            }

            dispatch({ type: 'error', payload: { code, message }});
            navigate('/error');
        }
    }   
    
    /**
     * A message has been received from the websocket
     */
    handleMessage = (msg) => {

        switch(msg.type) {

            case 'connected':
                const authMsg = AppLogic.createAuthMessage(state.token);
                connection.sendMessage(authMsg);
                break;
            case 'disconnected':
                // timer.pause();
                dispatch({ type: 'userConnected' , payload: false });
                dispatch({ type: 'partnerConnected' , payload: false });
                break;
            case 'auth':
                const userConnected = AppLogic.isUserConnected(state.uid, msg.members);
                const partnerConnected = AppLogic.isPartnerConnected(state.uid, msg.members);
                // userConnected && partnerConnected ? timer.start() : timer.pause();
                dispatch({ type: 'showErrorPage' , payload: false });
                dispatch({ type: 'userConnected' , payload: userConnected });
                dispatch({ type: 'partnerConnected' , payload: partnerConnected });
                dispatch({ type: 'partnerTyping' , payload: partnerConnected && state.partnerTyping });

                if (state.userTyping) {
                    const typingMsg = AppLogic.createTypingMessage(state.token, state.userTyping);
                    connection.sendMessage(typingMsg);
                }
                
                // redirect to chat page
                if (!state.authenticated && userConnected) {
                    dispatch({ type: 'authenticated', payload: userConnected });
                    navigate('/chat');
                }
                break;
            case 'typing':
                dispatch({ type: 'partnerTyping', payload: msg.state });
                break;
            case 'text':
                const newMsg = { key: msg.key, content: msg.content, status: 'received' };
                dispatch({ type: 'messages', payload: [ ...state.messages, newMsg ] });
                const ackMsg = AppLogic.createTextAckMessage(state.token, msg);
                connection.sendMessage(ackMsg);
                if (state.blurred) {
                    dispatch({ type: 'blurred', payload: false });
                    setTimeout(() => dispatch({ type: 'blurred', payload: true }), +BLUR_DELAY);
                }
                break;
            case 'ack':
                const messages = state.messages.map(m => m.key === msg.key ? { ...m, status: 'confirmed' } : m );
                dispatch({ type: 'messages', payload: messages });
                if (state.blurred) {
                    dispatch({ type: 'blurred', payload: false });
                    setTimeout(() => dispatch({ type: 'blurred', payload: true }), +BLUR_DELAY);
                }                
                break;
            case 'error':
                showError(msg.error);
                break;
            default:   
                // Ignore all other messages
        }
    
        // Change state so the message will not be handled multiple time
        dispatch({ type: 'received', payload: null });   
        state.received = null;
    }

    return {

        // general
        init,
        navigate,
        showError,

        // settings
        setLanguage,
        setTheme,
        setAutoBlur,
        setAutoExit,
        setAutoConfirm,
        setBlurredMessages,

        // chat management
        createCodes,
        startChat,
        stopChat,
        sendMessage,
        sendTyping,
        handleMessage
    }
}
