
import { useAppState } from '../../hooks//useAppState.js';
import image_dark from '../../assets/images/dark/msg-confirmed.svg';
import image_light from '../../assets/images/light/msg-confirmed.svg';

const MsgConfirmedIcon = ({ ...props }) => {

    const [ state ] = useAppState();
    const image = state.theme === 'dark' ? image_dark : image_light;

    return <img {...props} src={image} alt='message confirmed' />
}
export default MsgConfirmedIcon;
