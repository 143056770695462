
import { Link } from 'react-router-dom';
import { useAppState } from '../../hooks//useAppState.js';
import image_dark from '../../assets/images/dark/settings.svg';
import image_light from '../../assets/images/light/settings.svg';

const BtnSettings = ({ to, ...props }) => {

    const [ state ] = useAppState();
    const image = state.theme === 'dark' ? image_dark : image_light;

    return <Link to={to}><img {...props} src={image} alt='settings' /></Link>
}

export default BtnSettings;
