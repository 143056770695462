
import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Link from '../../components/Link.js';
import Logo from '../../components/Logo.js';
import config from '../../../package.json';

// Config environment
const YOULINK_URL = config.youlinkUrl ?? 'www.netlogix.ch';
const STARTUP_TIME = config.startupTime ?? '4000';

const Home = () => {

    const { t } = useTranslation();
    const [ params ] = useSearchParams();
    const code = params.get('code');
    const navigate = useNavigate();
    
    useEffect(() => {
        // Redirect to /code with param code converted to path param
        setTimeout(() => { navigate('/code' + (code ? `/${code}` : '')); }, +STARTUP_TIME);
    }, [navigate, code])

    return (

        <main className='valign-center text-center'>

            <Logo className='pt-25' />
            <p className='h2 pt-3'>{parse(t('home.title'))}</p>
            <p className='h5 fill'>{parse(t('home.message'))}</p>
            <Link className='back' to={'https://' + YOULINK_URL}>{YOULINK_URL}</Link>

        </main>
    )
}

export default Home;