
import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Link from '../../components/Link.js';

const Privacy = () => {

    const { t } = useTranslation();

    return (

        <main className='valign-center'>

            <div className='h2 pt-3 text-center'>{t('privacy.title')}</div>
            <div className='p-1 pb-2 text-left auto-overflow'>{parse(t('privacy.content'))}</div>
            <Link className='back' to='back'>{t('common.back')}</Link>

        </main>
    )
}

export default Privacy;
