
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations_en from './assets/translations/translations_en.json';

// Supported languages
const supportedLanguages = ['en', 'fr', 'de', 'it', 'es', 'pt'];

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: 'en',
        supportedLngs: supportedLanguages,
        returnObjects: true,
        resources: { 
            en: { translation: translations_en },
        },
    })

// i18n helper
i18next.supportedLanguages = supportedLanguages;