
import React from 'react';
import './AnimatedSquares.css';

const AnimatedSquares = ({ animated, className, ...props }) => {

    if (!animated) {
        return <div {...props} className={`animated-squares ${className}`} />
    }
    return (
        
        <div {...props} className={`animated-squares`}>
            <span><div className={className}></div></span>
            <span><div className={className}></div></span>
            <span><div className={className}></div></span>
            <span><div className={className}></div></span>
            <span><div className={className}></div></span>
        </div>
    )
}

export default AnimatedSquares;
