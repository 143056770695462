
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../hooks/useAppState.js';
import { useController } from '../../hooks/useController.js';
import TimeUtil from '../../utils/TimeUtil.js';
import AnimatedSquares from '../../components/AnimatedSquares.js';
import AnimatedDots from '../../components/AnimatedDots.js';
import ArrowUp from '../../components/ArrowUp.js';

import Logo from '../../components/Logo.js';
import BtnSettings from './BtnSettings.js';
import BtnLogoff from './BtnLogoff.js';
import BtnBlur from './BtnBlur.js';
import MsgEmptyIcon from './MsgEmptyIcon.js';
import MsgSentIcon from './MsgSentIcon.js';
import MsgReceivedIcon from './MsgReceivedIcon.js';
import MsgConfirmedIcon from './MsgConfirmedIcon.js';

import './Chat.css';

const Chat = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const [ state ] = useAppState();
    const controller = useController();
    
    const [status, setStatus] = useState('');
    const [canSendText, setCanSendText] = useState(false);
    const [text, setText] = useState('');

    /**
     * Page lifecycle
     */

    useEffect(() => {

        // Both members are connected
        if (state.userConnected && state.partnerConnected) {
            setStatus(t('chat.two-connected'));
        }
        // Only one member is connected
        else if ((state.userConnected && !state.partnerConnected) || (!state.userConnected && state.partnerConnected)) {
            setStatus(t('chat.one-connected'));
        }
        // Nobody is connected
        else {
            setStatus(t('chat.reconnecting'));
        }
    
    }, [state, t])

    /**
     * Page event handlers
     */

    const onBlurClicked = (e, clickDown) => {
        e.preventDefault();
        controller.setBlurredMessages(clickDown);
    }
    
    const onTextChange = (e) => {
        e.preventDefault();

        if (e.type === 'change') {

            const isTyping = e.target.value.trim().length > 0;
            const canSendText = e.target.value.trim().length > 3;

            setText(e.target.value);
            setCanSendText(canSendText);

            if (isTyping !== state.userTyping) controller.sendTyping(isTyping);
        }
        else if (canSendText) {

            if (e.type === 'click' || (e.type === 'keyup' && e.key === 'Enter')) {
                controller.sendMessage(text);
                setText('');
            }
        }
    }

    return (

        <main className='chat'>

            <div className='header'>

                <div className='row halign-between pt-2 ps-2 pe-2'>
                    <BtnSettings className='btn' to='/settings' />
                    <Logo className='header-logo' />
                    <BtnLogoff className='btn' to='/logoff' />
                </div>

                <div className='row pt-2 h5'><span>{TimeUtil.toString(state.connectionTime)}</span></div>

                <div className='row pt-2'><BtnBlur className='btn' onClick={onBlurClicked} /></div>

                <div className='row pt-2'>
                    <AnimatedSquares className='user-connected' animated={!state.userConnected} />&nbsp;
                    <AnimatedSquares className='partner-connected' animated={!state.partnerConnected} />
                </div>

                <div className='row h6'><span>{ status.toUpperCase() }</span></div>

            </div>

            <div className={`messages ${state.blurred ? 'blurred' : ''}`}>
                { 
                    state.messages.map(

                        (msg) => (

                            <div className='row' key={msg.key}>
                                
                                { msg.status === 'sent' && <MsgSentIcon /> }
                                { msg.status === 'received' && <MsgReceivedIcon /> }
                                { msg.status === 'confirmed' && <MsgConfirmedIcon /> }

                                <div className={msg.status === 'sent' || msg.status === 'confirmed' ? 'sent' : 'received'}>
                                    {msg.content}
                                </div>
                            </div>
                        )
                    )
                }

                { 
                    state.partnerTyping  && (
                        <div className='row'>
                            <MsgEmptyIcon />
                            <AnimatedDots />
                        </div>
                    )
                }
            </div>

            <div className='chatfield'> 

                <input
                    type='text'
                    value={text} 
                    placeholder={t('chat.input-placeholder')} 
                    onKeyUp={onTextChange}
                    onChange={onTextChange} 
                />

                <ArrowUp className='btn-send' enabled={canSendText} onClick={onTextChange}/>

            </div>

        </main>
    )
}

export default Chat;
