import React, { useState } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useController } from '../../hooks/useController.js';
import AppLogic from '../../logic/AppLogic.js';
import Link from '../../components/Link.js';
import Logo from '../../components/Logo.js';
import ArrowDown from '../../components/ArrowDown.js';

import './Code.css';

const CodeNew = () => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const controller = useController();

    const [userEmail, setUserEmail] = useState('');
    const [partnerEmail, setPartnerEmail] = useState('');
    const [enabled, setEnabled] = useState(false);

    const parseAndReplace = (label) => {
        
        const options = {
            replace: (node) => {

                switch(node.attribs?.id) {
                    case 'privacy': return <Link className='link' to='/privacy'>{domToReact(node.children, options)}</Link>;
                    case 'terms':   return <Link className='link' to='/terms'>{domToReact(node.children, options)}</Link>; 
                    case 'howto':   return <Link className='link' to='/howto'>{domToReact(node.children, options)}</Link>;   
                    default:        return null;
                }
            }
        }

        return parse(t(label), options);
    }

    /**
     * Event handler
     */

    const onValidateUserEmail = (email) => {
        const emailsValid = AppLogic.areEmailsValid([ email, partnerEmail ]);
        setUserEmail(email);
        setEnabled(emailsValid);
    }

    const onValidatePartnerEmail = (email) => {
        const emailsValid = AppLogic.areEmailsValid([ userEmail, email ]);
        setPartnerEmail(email);
        setEnabled(emailsValid);
    }

    const onCreateCodes = async () => {

        try {
            await controller.createCodes(userEmail, partnerEmail);
            controller.navigate('/code/sent');
        }
        catch(e) {
            controller.showError(e);
        }
    }

    return (

        <main className='valign-center'>

            <Logo className='pt-25' />

            <div className='h5 pt-4 text-center'>{t('codenew.useremail')}</div>

            <input 
                type='text' 
                value={userEmail} 
                className='h5 p-2 text-center border' 
                placeholder={t('codenew.placeholder')} 
                onChange={(e) => onValidateUserEmail(e.target.value)}
            />

            <div className='h5 pt-4 text-center'>{t('codenew.partneremail')}</div>

            <input 
                type='text' 
                value={partnerEmail} 
                className='h5 p-2 text-center border' 
                placeholder={t('codenew.placeholder')} 
                onChange={(e) => onValidatePartnerEmail(e.target.value)}
            />

            <ArrowDown className='btn-start' enabled={enabled} />

            <div className='fill'>
                <div className={'h4 p-1 ' + ( enabled ? 'btn-start-enabled' : 'btn-start-disabled')} onClick={onCreateCodes}>
                    {t('codenew.send')}
                </div>
            </div>

            <Link className='back' to='back'>{t('common.back')}</Link>
            <div className='p-1 pb-2 text-center'>{parseAndReplace('code.legal')}</div>

        </main>
    )
}

export default CodeNew;
