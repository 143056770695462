
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../hooks/useAppState.js';
import Link from '../../components/Link.js';
import Logo from '../../components/Logo.js';

const Error = () => {

    const { t } = useTranslation();
    const [ state ] = useAppState();

    return (

        <main className='valign-center text-center'>

            <Logo className='pt-25' />

            <p className='h2 pt-3'>{t('error.title')}</p>
            
            <div>

                <p className='h5 p-1'>
                    {t('error.message')}
                </p>
                
                <p className='p-1'>
                    <strong>{state.error.message ?? '-' }</strong>
                </p>

                {
                    state.error.code &&
                    <p className='p-1'>
                        code = {state.error.code}
                    </p>
                }

            </div>

            <Link className='back' to='back'>{t('common.back')}</Link>

        </main>
    )
}

export default Error;
