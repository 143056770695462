
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App.js';
import { AppState } from './hooks/useAppState.js';
import { AppConnection } from './hooks/useConnection.js';

import './i18n.js';
import './miniboot.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <Router>
        <AppState>
            <AppConnection>
                <App />
            </AppConnection>
        </AppState>
    </Router>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

