
import React from 'react';
import { useController } from '../hooks/useController.js';
import { Link } from 'react-router-dom';

/**
 * Create a clickable item (hyperlink).
 */

const CustomLink = ({ className, to, onClick, onMouseDown, onMouseUp, children }) => {

    const controller = useController();

    // Detect if it's an external link
    if (to?.startsWith('http') || to?.startsWith('mailto')) {
        return <a className={className} href={to}>{children}</a>;
    }

    // Detect if it's a go back link
    else if (to === 'back') {
        return <span className={className} onClick={() => controller.navigate('back')}>{children}</span>
    }

    // Detect if the link is clickable (mouse event)
    else if (typeof onMouseDown === 'function' || typeof onMouseUp === 'function') {
        return <span className={className} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchStart={onMouseDown} onTouchEnd={onMouseDown}>{children}</span>
    }

    // Detect if the link is clickable
    else if (typeof onClick === 'function') {
        return <span className={className} onClick={onClick}>{children}</span>
    }

    // Otherwise, it's an internal link (standard react-router-link)
    return <Link className={className} to={to}>{children}</Link>
}

export default CustomLink;
