
import { useAppState } from '../hooks//useAppState.js';
import image_dark from '../assets/images/dark/logo.svg';
import image_light from '../assets/images/light/logo.svg';

const Logo = ({ className, ...props }) => {

    const [ state ] = useAppState();
    const image = state.theme === 'dark' ? image_dark : image_light;

    return <img  {...props} className={'logo ' + className} src={image} alt='logo' />
}

export default Logo;
