
import { useAppState } from '../../hooks//useAppState.js';
import image_dark from '../../assets/images/dark/msg-received.svg';
import image_light from '../../assets/images/light/msg-received.svg';

const MsgReceivedIcon = ({ ...props }) => {

    const [ state ] = useAppState();
    const image = state.theme === 'dark' ? image_dark : image_light;

    return <img {...props} src={image} alt='message received' />
}
export default MsgReceivedIcon;
